import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import DashBoard from "./Pages/Dashoard/Dashboard";
import Login from "./Pages/Login/Login";
import AddInvoice from "./Pages/AddInvoice/PageAdd";
import BillingManagement from "./Pages/BillingManagement/BillingManagement";
import SearchInvoices from "./Pages/SearchInvoices/SearchInvoices";
import Governorates from "./Pages/Home/Governorates/Governorates";
import ItemsGroup from "./Pages/Home/ItemsGroup/ItemsGroup";
import Categories from "./Pages/Home/Categories/Categories";
import Delegate from "./Pages/Home/Delegate/Delegate";
import Invoices from "./Pages/Invoices/InvoicesPage";
import ChatApp from "./Pages/ChatApp/ChatApp";
import Roles from "./Pages/Roles/Roles";
import Test from "./Pages/Test/Test";
import NotFound from "./Pages/NotFound/NotFound";
import InvoiceDailyLate from "./Pages/InvoiceDailyLate/InvoiceDailyLatePage";
import { getCookie } from "./Pages/Login/Login";
import "./Component/StyleSweetAlert.css";
import "./assets/css/all.css";
import { useNavigate } from "react-router-dom";
import ColorPicker from "./Component/Settings/Settings";
import { SignalRProvider } from "./context/SingleRProvider";
import "./App.css";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./FireBase/FireBaseConfig";
function App() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        await Notification.requestPermission();

        if (Notification.permission === "granted") {
          getToken(messaging, {
            vapidKey:
              "BHzzUoAPXH5rbBE4GOnbe3fDQujjwFSiE-8XWfrCDa4jwDG62XyYhSs1Ik2QlRJGUMgkaZFtXjuEbVc_YLYA5Rs", // Make sure to use your VAPID key here
          })
            .then((currentToken) => {
              if (currentToken) {
                console.log("FCM Token:", currentToken);
              } else {
                console.log("No FCM token available.");
              }
            })
            .catch((err) => {
              console.error("Error getting FCM token:", err);
            });
        } else {
          console.log("Permission not granted for notifications");
        }
      } catch (error) {
        console.error("Error requesting permission:", error);
      }
    };

    requestPermission();

    navigator.serviceWorker.addEventListener("message", (event) => {
      console.log("New message received:", event.data);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const userCookie = getCookie("user");
    if (userCookie) {
      try {
        setUserData(JSON.parse(userCookie));
      } catch (e) {
        console.error("Error parsing user cookie:", e);
      }
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const cookieValue = getCookie("user");
    if (!cookieValue) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      {userData && <ColorPicker />}
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/home-page" element={<DashBoard />} />
        <Route path="/invoices/:Name" element={<InvoiceDailyLate />} />
        <Route
          path="/add-invoice"
          element={<AddInvoice userData={userData} />}
        />
        <Route path="/billing-management" element={<BillingManagement />} />
        <Route path="/search-invoices" element={<SearchInvoices />} />
        <Route path="/home/governorates" element={<Governorates />} />
        <Route path="/home/items-group" element={<ItemsGroup />} />
        <Route path="/home/categories" element={<Categories />} />
        <Route path="/home/delegate" element={<Delegate />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route
          path="/chat-app"
          element={
            <SignalRProvider>
              <ChatApp />
            </SignalRProvider>
          }
        />
        <Route path="/accounts" element={<Roles />} />
        <Route path="/test" element={<Test />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;

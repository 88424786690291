import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  Modal,
  Box,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import usePrintInvoices from "../../../hooks/UsePrint";
import usePrintInvoicesSingle from "../../../hooks/UsePrintInvoices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSave } from "@fortawesome/free-solid-svg-icons";

import {
  fetchDataWithRetries,
  fetch,
  putData,
} from "../../../Component/function/FunctionApi";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import useShowInvoice from "../../../hooks/invoice/ShowInvoice";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

const DataTable = () => {
  const printRepInvoices = usePrintInvoices();
  const printRepInvoicesSingle = usePrintInvoicesSingle();
  const invoiceCodeRef = useRef();
  const invoiceDateRef = useRef();
  const customerRef = useRef();
  const addressRef = useRef();
  const governorateRef = useRef();
  const phoneRef = useRef();
  const invoiceCategoriesRef = useRef();
  const dateCreatedRef = useRef();
  const userNameRef = useRef();
  const statusRef = useRef();
  const totalPriceRef = useRef();
  const [rows, setRows] = useState({ data: [] });
  const [searchTerms, setSearchTerms] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const debouncedSearchTerms = useDebounce(searchTerms, 300);
  const [statusMessage, setStatusMessage] = useState(null);
  const [openRepModal, setOpenRepModal] = useState(false);
  const [representatives, setRepresentatives] = useState([]);
  const [selectedRepresentative, setSelectedRepresentative] = useState("");

  const [showPrintModel, setShowPrintModel] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const [statusMessageModalOpen, setStatusMessageModalOpen] = useState(false);
  const fetchInvoices = async () => {
    setLoading(true);
    try {
      await fetchDataWithRetries("Invoice/get-invoice-pagination", setRows);

      await fetchDataWithRetries(
        "Represntative/get-Represntatives",
        setRepresentatives
      );
    } catch (error) {
      setError("Error fetching data after multiple retries");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvoices();
  }, []);

  const { showInvoice } = useShowInvoice();

  // Handle search input changes
  const handleSearch = (column, value) => {
    setSearchTerms((prev) => ({ ...prev, [column]: value }));
  };

  const filteredRows = useMemo(() => {
    const hasActiveSearchTerms = Object.values(debouncedSearchTerms).some(
      (term) => term && term.toString().trim() !== ""
    );

    if (!rows.data || !hasActiveSearchTerms) return [];

    return rows.data.filter((row) => {
      return Object.keys(debouncedSearchTerms).every((column) => {
        const searchTerm =
          debouncedSearchTerms[column]?.toString().toLowerCase() || "";
        if (!searchTerm) return true;

        if (column === "governorate") {
          return row.governorate?.name?.toLowerCase().includes(searchTerm);
        }
        if (column === "itemsCount") {
          return row.itemsCount?.toString().includes(searchTerm);
        }
        if (column === "fullName") {
          return row.representative?.fullName
            ?.toLowerCase()
            .includes(searchTerm);
        }
        if (column === "invoiceCode") {
          const searchParts = searchTerm.split("-").map((part) => part.trim());
          return searchParts.some((part) =>
            row.invoiceCode?.toString().toLowerCase().includes(part)
          );
        }

        return row[column]?.toString().toLowerCase().includes(searchTerm);
      });
    });
  }, [rows, debouncedSearchTerms]);

  const handleSelectAll = (event) => {
    setSelectedRows(
      event.target.checked ? filteredRows.map((row) => row.id) : []
    );
  };
  console.log(rows);
  const handleOpenRepModal = () => {
    setOpenRepModal(true);
  };
  const handleRowSelect = (event, row) => {
    setSelectedRows((prev) =>
      event.target.checked
        ? [...prev, row.id]
        : prev.filter((id) => id !== row.id)
    );
  };

  const handleExportToExcel = () => {
    console.log("dfsdfsvsdfvvsdfvsdfsdfvsdfv");
    const ws = XLSX.utils.json_to_sheet(filteredRows);
    console.log(filteredRows);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "فواتير");

    XLSX.writeFile(wb, "فواتير.xlsx");
  };
  const handlePrint = () => {
    if (selectedRows.length === 0) {
      alert("لا يوجد بيانات للطباعة");
      return;
    }

    let data = [];
    filteredRows.forEach((row) => {
      if (selectedRows.includes(row.id)) {
        data.push(row);
      }
    });

    printRepInvoicesSingle(data);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStatus("");
    setStatusMessage(null);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    try {
      await putData("Invoice/update-invoice-status", {
        InvoiceIds: selectedRows,
        InvoiceStatus: selectedStatus,
      });
      setStatusMessage("تم تعديل حالة الفاتورة بنجاح");
      await fetchDataWithRetries("Invoice/get-invoice-pagination", setRows);
    } catch (error) {
      Swal.fire("Error", error.response.data.message, "error");
    } finally {
      setStatusMessageModalOpen(true);
      handleCloseModal();
    }
  };
  const handleClearSearch = () => {
    invoiceCodeRef.current.value = "";
    invoiceDateRef.current.value = "";
    customerRef.current.value = "";
    addressRef.current.value = "";
    governorateRef.current.value = "";
    phoneRef.current.value = "";
    invoiceCategoriesRef.current.value = "";
    dateCreatedRef.current.value = "";
    userNameRef.current.value = "";
    statusRef.current.value = "";
    totalPriceRef.current.value = "";

    setSearchTerms({
      invoiceCode: "",
      invoiceDate: "",
      customer: "",
      address: "",
      governorate: "",
      phone: "",
      invoiceCategories: "",
      dateCreated: "",
      userName: "",
      status: "",
      totalPrice: "",
    });
  };

  const handleUpdateRepresentative = async () => {
    const requestBody = {
      invoiceIds: selectedRows,
      newRepresentativeId:
        selectedRepresentative === "null" ? null : selectedRepresentative,
      dateDaily: new Date(selectedDate).toISOString(),
    };

    try {
      await putData("Invoice/update-representative-invoices", requestBody);
      setStatusMessage("تم تعديل المندوب بنجاح");
      window.location.reload();
    } catch (error) {
      setStatusMessage(error.response.data.message);
      console.error("Error updating status:", error.response);
    } finally {
      setStatusMessageModalOpen(true);
      setOpenRepModal(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>{error}</div>;

  const handelPrintDaily = async () => {
    console.log("start print");
    console.log(selectedRepresentative, selectedDate);

    try {
      const url = `Represntative/get-dailyDateOfRepresntative?represntativeId=${selectedRepresentative}&dateDaily=${selectedDate}`;
      console.log(url);
      const response = await fetch(url);
      const data = await response.data;
      if (data[0].invoices.length === 0) {
        console.log("error");
        setShowPrintModel(false);
        Swal.fire({
          icon: "info",
          title: "لا يوجد  فواتير",
          text: "لا يوجد فواتير لهذا المندوب  في هذه اليوم",
        });
      } else {
        console.log(data);
        printRepInvoices(data);
      }
    } catch (error) {
      Swal.fire({
        icon: "خطا",
        title: "Error",
        text: "حدث خطاء في جلب البيانات اليومية",
      });
      console.error("Error fetching daily data:", error);
    }
  };

  const renderSearchInput = (column, placeholder, ref) => (
    <TableCell key={column}>
      <TextField
        variant="outlined"
        size="small"
        type={`${
          column == "dailyDate" || column == "invoiceDate" ? "date" : "text"
        }`}
        placeholder={placeholder}
        value={searchTerms[column] || ""}
        onChange={(e) => handleSearch(column, e.target.value)}
        inputRef={ref}
      />
    </TableCell>
  );

  return (
    <div>
      <Paper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 16px",
          }}
        >
          <Button onClick={handleExportToExcel}>
            <FontAwesomeIcon icon={faSave} /> تصدير إلى Excel
          </Button>
          <button
            className="px-4 my-3 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            onClick={handleClearSearch}
          >
            مسح
          </button>
          <Button onClick={() => handlePrint()}>
            <FontAwesomeIcon icon={faPrint} /> طباعة
          </Button>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {rows.data && rows.data.length > 0 && (
                  <>
                    {renderSearchInput("invoiceCode", "كود", invoiceCodeRef)}
                    {renderSearchInput(
                      "invoiceDate",
                      "التاريخ",
                      invoiceDateRef
                    )}
                    {renderSearchInput("customer", "العميل", customerRef)}
                    {renderSearchInput("createdBy", "السكرتريه", customerRef)}

                    {renderSearchInput("address", "العنوان", addressRef)}
                    {renderSearchInput(
                      "governorate",
                      "المحافظة",
                      governorateRef
                    )}
                    {renderSearchInput("phone", "الهاتف", phoneRef)}
                    {renderSearchInput(
                      "invoiceCategories",
                      "الفئات",
                      invoiceCategoriesRef
                    )}
                    {renderSearchInput(
                      "dailyDate",
                      "تاريخ اضافه مندوب",
                      dateCreatedRef
                    )}
                    {renderSearchInput("fullName", "المندوب", userNameRef)}
                    {renderSearchInput("status", "الحالة", statusRef)}
                    {renderSearchInput("totalPrice", "الإجمالي", totalPriceRef)}
                  </>
                )}
              </TableRow>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.length === filteredRows.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell className="table-header">كود</TableCell>
                <TableCell className="table-header">التاريخ</TableCell>
                <TableCell className="table-header">العميل</TableCell>
                <TableCell className="table-header">السكرتيره</TableCell>
                <TableCell className="table-header">العنوان</TableCell>
                <TableCell className="table-header">المحافظة</TableCell>
                <TableCell className="table-header">الهاتف</TableCell>
                <TableCell className="table-header">الفئات</TableCell>
                <TableCell className="table-header">
                  تاريخ اضافه مندوب
                </TableCell>
                <TableCell className="table-header">المندوب</TableCell>
                <TableCell className="table-header">الحالة</TableCell>
                <TableCell className="table-header">الإجمالي</TableCell>
                <TableCell className="table-header">عرض</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.length > 0 ? (
                filteredRows.map((row) => (
                  <TableRow
                    key={row.id}
                    className={
                      selectedRows.includes(row.id) ? "selected-row" : ""
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(row.id)}
                        onChange={(event) => handleRowSelect(event, row)}
                      />
                    </TableCell>
                    <TableCell>{row.invoiceCode}</TableCell>
                    <TableCell>{row.invoiceDate.split("T")[0]}</TableCell>
                    <TableCell>{row.customer}</TableCell>
                    <TableCell>{row.createdBy || ""}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.governorate?.name}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>
                      {row.invoiceCategories
                        ?.map((cat) => cat.category.name)
                        .join(", ")}
                    </TableCell>
                    <TableCell>{row.dailyDate.split("T")[0]}</TableCell>
                    <TableCell>
                      {row.representative?.fullName ?? "لا يوجد مندوب"}
                    </TableCell>

                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.totalPrice}</TableCell>
                    <TableCell>
                      <button
                        className="open-modal-button"
                        onClick={() => showInvoice(row)}
                      >
                        عرض
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <div className="div-open-modal">
        <div className="flex justify-center items-center">
          <div className="all-button">
            {selectedRows.length > 0 && (
              <div>
                <button className="open-modal-button" onClick={handleOpenModal}>
                  تعديل حالة الفاتورة
                </button>
              </div>
            )}
            <div>
              <button
                className="open-modal-button"
                onClick={() => setShowPrintModel(true)}
              >
                طباعه يوميه مندوب
              </button>
            </div>
            <div>
              {selectedRows.length > 0 && (
                <button
                  className="open-modal-button"
                  onClick={handleOpenRepModal}
                >
                  تعديل المندوب
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal open={openModal} onClose={handleCloseModal} dir="rtl">
        <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
          <Typography variant="h6">تحديث حالة الفواتير</Typography>
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            displayEmpty
            fullWidth
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="" disabled>
              اختر الحالة الجديدة
            </MenuItem>
            <MenuItem value={1}>جديد</MenuItem>
            <MenuItem value={2}>تحت التجهيز</MenuItem>
            <MenuItem value={3}>تم التسليم</MenuItem>
            <MenuItem value={4}>مرتجع</MenuItem>
            <MenuItem value={5}>ملغي</MenuItem>
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateStatus}
          >
            تأكيد
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            style={{ marginRight: 8 }}
          >
            إلغاء
          </Button>
        </Box>
      </Modal>
      {openRepModal && (
        <Modal
          open={openRepModal}
          onClose={() => setOpenRepModal(false)}
          dir="rtl"
        >
          <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
            <Typography variant="h6">تحديث المندوب</Typography>
            <Select
              value={selectedRepresentative}
              onChange={(e) => setSelectedRepresentative(e.target.value)}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="" disabled>
                اختر المندوب
              </MenuItem>
              {representatives?.data.map((rep) => (
                <MenuItem key={rep.id} value={rep.id}>
                  {rep.fullName}
                </MenuItem>
              ))}
              <MenuItem value="null">_</MenuItem>
            </Select>
            <TextField
              variant="outlined"
              label="التاريخ"
              type="date"
              fullWidth
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateRepresentative}
            >
              تأكيد
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenRepModal(false)}
              style={{ marginRight: 8 }}
            >
              إلغاء
            </Button>
          </Box>
        </Modal>
      )}

      {showPrintModel && (
        <Modal
          open={showPrintModel}
          onClose={() => setOpenRepModal(false)}
          dir="rtl"
        >
          <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
            <Typography variant="h6">طباعه يوميه المندوب</Typography>
            <Select
              value={selectedRepresentative}
              onChange={(e) => setSelectedRepresentative(e.target.value)}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="" disabled>
                اختر المندوب
              </MenuItem>
              {representatives?.data.map((rep) => (
                <MenuItem key={rep.id} value={rep.id}>
                  {rep.fullName}
                </MenuItem>
              ))}
            </Select>
            <TextField
              variant="outlined"
              label="التاريخ"
              type="date"
              fullWidth
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handelPrintDaily}
            >
              طباعه
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowPrintModel(false)}
              style={{ marginRight: 8 }}
            >
              إلغاء
            </Button>
          </Box>
        </Modal>
      )}
      <Modal
        open={statusMessageModalOpen}
        onClose={() => {
          setStatusMessageModalOpen(false);
        }}
        dir="rtl"
      >
        <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
          <Typography variant="h5" sx={{ padding: 2, borderRadius: 2 }}>
            {statusMessage}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setStatusMessageModalOpen(false)}
          >
            إغلاق
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default DataTable;

import React, { useState, useEffect } from "react";
import { postData, putData, Url } from "../../Component/function/FunctionApi";
import logo from "../../assets/images/weviira.png";
import Swal from "sweetalert2";

import {
  fetchDataWithRetries,
  deleteFunction,
} from "../../Component/function/FunctionApi";
import "./Invoices.css";
import { BsPencilSquare } from "react-icons/bs";
import { FaEye, FaPlus, FaPrint } from "react-icons/fa";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FaTrash } from "react-icons/fa";
import { getCookie } from "../Login/Login";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import usePrintInvoice from "../../hooks/invoice/printInvoice";
import useShowInvoice from "../../hooks/invoice/ShowInvoice";
import InvoiceDialog from "../../Component/EditInvoice/EditInvoice";
import ItemModal from "../../Component/EditItem/EditItem";
import InvoicesTable from "../../Component/invoicePage/Invoices";
const getToken = () => {
  const userCookie = getCookie("user");
  return userCookie ? JSON.parse(userCookie).token : null;
};
const Invoices = () => {
  const { handlePrintInvoice } = usePrintInvoice(logo);
  const { showInvoice } = useShowInvoice();
  const [representatives, setRepresentatives] = useState([]);
  const [selectedRepresentative, setSelectedRepresentative] = useState("");
  const [categories, setCategories] = useState([]);
  const [invoicesData, setInvoicesData] = useState({ data: [] });
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [selectedGovernorate, setSelectedGovernorate] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogType, setDialogType] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [newItem, setNewItem] = useState({
    categoryId: "",
    quantity: 0,
    price: 0,
    discount: 0,
  });

  const handleDeleteItem = async (item) => {
    // Show SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      await deleteFunction(
        `Invoice/delete-invoice-categoryByid?categoryid=${item.id}`
      );

      setSelectedInvoiceItems((prev) => prev.filter((i) => i.id !== item.id));
      window.location.reload();
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  };
  const printInvoice = (invoice) => {
    handlePrintInvoice(invoice);
  };
  const handleSaveItem = async (e) => {
    e.preventDefault();
    try {
      // Prepare form data

      const arr = [
        {
          id: newItem.categoryId,
          quantity: newItem.quantity,
          price: newItem.price,
          discount: newItem.discount,
        },
      ];
      await axios.post(
        `${Url}Invoice/AddInvoiceCategory?invoiceId=${selectedInvoice.id}`,
        arr,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Close the modal and notify the user
      handleCloseModal();
      Swal.fire({
        title: "Success",
        text: "Item added successfully!",
        icon: "success",
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        window.location.reload();
      });
    } catch (e) {
      // Log and display error message
      console.error("Error saving item:", e);
      Swal.fire("Error", "Failed to save the item. Please try again.", "error");
    }
  };

  const handleEditItemRequest = async (e) => {
    e.preventDefault();
    try {
      // Prepare form data

      const arr = [
        {
          invoiceCategoryId: newItem.categoryId,
          quantity: newItem.quantity,
          price: newItem.price,
          discount: newItem.discount,
        },
      ];
      await axios.put(
        `${Url}Invoice/invoice-categories?invoiceId=${selectedInvoice.id}`,
        arr,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Close the modal and notify the user
      handleCloseModal();
      Swal.fire({
        title: "Success",
        text: "Item added successfully!",
        icon: "success",
        timer: 3000,
        timerProgressBar: true,
      }).then(() => {
        window.location.reload();
      });
    } catch (e) {
      // Log and display error message
      console.error("Error saving item:", e);
      Swal.fire("Error", "Failed to save the item. Please try again.", "error");
    }
  };

  const handleEditItem = (item) => {
    console.log(item);
    setNewItem({
      categoryId: item.id || "",
      quantity: item.quantity || 0,
      price: item.price || 0,
      discount: item.discount || 0,
    });
    setIsModalOpen(true);
    setEditMode(true);
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.id === parseInt(e.target.value)
    );

    setNewItem({
      ...newItem,
      categoryId: selectedCategory.id,
    });
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);

    setNewItem({ id: "", quantity: 0, price: 0, discount: 0 });
  };
  const getToken = () => {
    const userCookie = getCookie("user");
    return userCookie ? JSON.parse(userCookie).token : null;
  };

  console.log(selectedInvoiceItems);
  useEffect(() => {
    const fetchGovernorates = async () => {
      await fetchDataWithRetries(
        "Represntative/get-Represntatives",
        setRepresentatives
      );

      const response = await axios.get(`${Url}Category/get-categories`);
      setCategories(response.data.data);
      fetchDataWithRetries();
      try {
        console.log("numberssssssssssssssssssssss");
        const numberResponse = await axios.get(
          `${Url}Invoice/GetPhoneNumbers`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        setNumbers(numberResponse.data.data);
        const response = await axios.get(`${Url}Governorate/get-governorates`);
        setGovernorates(response.data.data);
      } catch (error) {
        console.error("Error fetching governorates:", error);
      }
    };
    fetchGovernorates();
  }, [getToken()]);

  const fetchInvoices = async () => {
    const result = await fetchDataWithRetries(
      "Invoice/GetAllInvoices",
      setInvoicesData
    );
    if (result && result.data) {
      setInvoicesData(result);
      setFilteredInvoices(result.data);
    }
  };
  const handleOpenDialog = (invoice) => {
    setSelectedInvoice(invoice);
    console.log(invoice);
    setSelectedInvoiceItems(invoice.invoiceCategories);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveChanges = async () => {
    console.log(selectedInvoice);

    console.log(selectedInvoice);
    try {
      console.log("Updated Invoice:", selectedInvoice);
      const response = await putData(
        `Invoice/update/${selectedInvoice.id}`,
        selectedInvoice
      );

      if (response.status === 200) {
        setDialogMessage("تم تحديث الفاتورة بنجاح!");
        setDialogType("success");
      } else {
        setDialogMessage("فشل في تحديث الفاتورة. حاول مرة أخرى.");
        setDialogType("error");
      }
      Swal.fire({
        title: "Success!",
        text: "Item was added successfully to the invoice.",
        icon: "success",
        confirmButtonText: "OK",
        timer: 3000,

        customClass: {
          confirmButton: "swal-confirm-btn",
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 200);
      setOpenDialog(false);
    } catch (error) {
      setOpenDialog(false);
      console.error("Error updating invoice:", error);
      setDialogMessage("حدث خطأ. حاول مرة أخرى.");
      setDialogType("error");
      setDialogOpen(true); // Open dialog
    }
  };
  console.log(invoicesData);
  const handleClose = () => {
    fetchInvoices();
    setDialogOpen(false);
    window.location.reload();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedInvoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDelete = async (invoice) => {
    console.log(invoice);
    try {
      const result = await Swal.fire({
        title: "هل أنت متأكد؟",
        text: "لا يمكنك التراجع عن هذا الإجراء بعد الحذف!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e63946",
        cancelButtonColor: "#457b9d",
        confirmButtonText: "نعم، احذفها!",
        cancelButtonText: "إلغاء",
      });

      if (result.isConfirmed) {
        await deleteFunction(`Invoice/invoice?id=${invoice.id}`);
        fetchInvoices();
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
      Swal.fire(
        "Error!",
        "An unexpected error occurred.",
        error.response.data.message
      );
    }
  };

  const [filters, setFilters] = useState({
    code: "",
    date: "",
    client: "",
    phone: "",
    address: "",
    user: "",
    representative: "",
    status: "",
    quantity: "",
    governorate: "",
  });

  // Fetch invoices data
  useEffect(() => {
    fetchInvoices();
  }, []);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      setFilters({ ...filters, [name]: value });
    }
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    if (invoicesData.data) {
      // Filter invoices based on search criteria
      const filtered = invoicesData.data.filter((invoice) => {
        const searchCodeParts = filters.code
          ? filters.code.split("-").map((part) => part.trim())
          : [];

        return (
          (filters.code === "" ||
            searchCodeParts.some((part) =>
              invoice.invoiceCode.toString().includes(part)
            )) &&
          (filters.date === "" || invoice.invoiceDate.includes(filters.date)) &&
          (filters.client === "" ||
            invoice.customer.includes(filters.client)) &&
          (filters.phone === "" || invoice.phone.includes(filters.phone)) &&
          (filters.address === "" ||
            invoice.address.includes(filters.address)) &&
          // (filters.representative === '' || invoice.representative.fullName.includes(filters.representative)) &&
          (filters.status === "" || invoice.status.includes(filters.status)) &&
          (filters.governorate === "" ||
            invoice.governorate.name.includes(filters.governorate))
        );
      });

      setFilteredInvoices(filtered);
    }
  }, [filters, invoicesData]);

  const handleClearSearch = () => {
    setFilters({
      code: "",
      date: "",
      client: "",
      phone: "",
      address: "",
      user: "",
      representative: "",
      status: "",
      quantity: "",
      governorate: "",
    });
  };

  const indexOfLastInvoice = currentPage * itemsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
  const currentInvoices = filteredInvoices.slice(
    indexOfFirstInvoice,
    indexOfLastInvoice
  );
  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

  return (
    <div className="invoices-container">
      <h2>فواتير</h2>
      <hr />
      <div className="containerInput">
        <div className="w-[100%]  flex items-center justify-center">
          <button
            className="px-4 my-3 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            onClick={handleClearSearch} // Clear inputs on button click
          >
            مسح
          </button>
        </div>
        <div className="input-group">
          <label>
            الكود
            <input
              type="text"
              name="code"
              value={filters.code}
              onChange={handleFilterChange}
              placeholder="الكود"
            />
          </label>
          <label>
            التاريخ
            <input
              type="date"
              name="date"
              value={filters.date}
              onChange={handleFilterChange}
            />
          </label>
          <label>
            العميل
            <input
              type="text"
              name="client"
              value={filters.client}
              onChange={handleFilterChange}
              placeholder="العميل"
            />
          </label>
          <label>
            التليفون
            <input
              type="text"
              name="phone"
              value={filters.phone}
              onChange={handleFilterChange}
              placeholder="التليفون"
            />
          </label>
          <label>
            العنوان
            <input
              type="text"
              name="address"
              value={filters.address}
              onChange={handleFilterChange}
              placeholder="العنوان"
            />
          </label>
        </div>
        <div className="input-group">
          <label>
            المستخدم
            <input
              type="text"
              name="user"
              value={filters.user}
              onChange={handleFilterChange}
              placeholder="المستخدم"
            />
          </label>
          <label>
            المندوب
            <input
              type="text"
              name="representative"
              value={filters.representative}
              onChange={handleFilterChange}
              placeholder="المندوب"
            />
          </label>
          <label>
            الحالة
            <input
              type="text"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              placeholder="الحالة"
            />
          </label>
          <label>
            الكمية
            <input
              type="number"
              name="quantity"
              value={filters.quantity}
              onChange={handleFilterChange}
              placeholder="الكميه"
            />
          </label>
          <label>
            المحافظه
            <input
              type="text"
              name="governorate"
              value={filters.governorate}
              onChange={handleFilterChange}
              placeholder="الكميه"
            />
          </label>
        </div>
      </div>
      <div className="table-container">
        <div className="scrollable-table">
          <InvoicesTable
            currentInvoices={currentInvoices}
            handlePrintInvoice={handlePrintInvoice}
            handleOpenDialog={handleOpenDialog}
            handleDelete={handleDelete}
            showInvoice={showInvoice}
          />
        </div>

        <InvoiceDialog
          openDialog={openDialog}
          selectedInvoice={selectedInvoice}
          governorates={governorates}
          representatives={representatives}
          selectedInvoiceItems={selectedInvoiceItems}
          handleInputChange={handleInputChange}
          handleCloseDialog={handleCloseDialog}
          setSelectedInvoice={setSelectedInvoice} // Added prop
          handleSaveChanges={handleSaveChanges}
          handleDeleteItem={handleDeleteItem}
          handleEditItem={handleEditItem}
          setIsModalOpen={setIsModalOpen}
        />
        <ItemModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          editMode={false}
          newItem={newItem}
          setNewItem={setNewItem}
          categories
          handleCategoryChange={handleCategoryChange}
          handleEditItemRequest={handleEditItemRequest}
          handleSaveItem={handleSaveItem}
        />

        <div className="pagination">
          <span>
            صفحة {currentPage} من {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
          >
            السابق
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage(Math.min(totalPages, currentPage + 1))
            }
            disabled={currentPage === totalPages}
          >
            التالي
          </button>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          {dialogType === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <h3 className="text-center p-2 ">{dialogMessage}</h3>
        </DialogContent>
        <DialogActions>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
            onClick={handleClose}
            color="primary"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Invoices;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave } from "@fortawesome/free-solid-svg-icons";

const ItemModal = ({
  isModalOpen,
  handleCloseModal,
  editMode,
  newItem,
  setNewItem,
  categories,
  handleCategoryChange,
  handleEditItemRequest,
  handleSaveItem,
}) => {
  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
        <div className="modal-header flex justify-between items-center">
          <h3 className="text-lg font-semibold">
            {editMode ? "تعديل الصنف" : "إضافة صنف جديد"}
          </h3>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-xl text-gray-500 hover:text-gray-700 cursor-pointer"
            onClick={handleCloseModal}
          />
        </div>
        <hr className="my-4" />
        <div className="modal-body">
          <div className="form-row grid grid-cols-1 gap-4">
            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700">
                الصنف
              </label>
              <select
                value={newItem.categoryId}
                onChange={handleCategoryChange}
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">اختر الصنف</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700">
                الكمية
              </label>
              <input
                type="number"
                value={newItem.quantity}
                onChange={(e) =>
                  setNewItem({ ...newItem, quantity: parseInt(e.target.value) })
                }
                placeholder="الكمية"
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="form-row grid grid-cols-1 gap-4 mt-4">
            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700">
                السعر
              </label>
              <input
                type="number"
                value={newItem.price}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value > 0 || e.target.value === "") {
                    setNewItem({ ...newItem, price: value });
                  }
                }}
                placeholder="السعر"
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="form-group">
              <label className="block text-sm font-medium text-gray-700">
                الخصم
              </label>
              <input
                type="number"
                value={newItem.discount}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value > 0 || e.target.value === "") {
                    setNewItem({ ...newItem, discount: value });
                  }
                }}
                placeholder="الخصم"
                className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer mt-4">
          <button
            className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onClick={editMode ? handleEditItemRequest : handleSaveItem}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            {editMode ? "حفظ التعديلات" : "إضافة الصنف"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemModal;

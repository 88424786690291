import React from "react";
import { FaPlus } from "react-icons/fa";

const InvoiceDialog = ({
  openDialog,
  handleCloseDialog,
  selectedInvoice,
  setSelectedInvoice,
  governorates,
  representatives,
  selectedInvoiceItems,
  handleInputChange,
  handleDeleteItem,
  handleEditItem,
  handleSaveChanges,
  setIsModalOpen,
}) => {
  return (
    openDialog && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          className="fixed inset-0 bg-black bg-opacity-30 z-40"
          onClick={handleCloseDialog} // Close on backdrop click
        ></div>

        <div className="relative z-50 bg-white rounded-lg shadow-lg p-6 max-w-4xl w-full">
          <div className="rtl">
            {/* Title */}
            <h2 className="text-2xl font-semibold mb-4 text-center text-gray-700">
              تعديل الفاتورة
            </h2>

            {/* Customer Input */}
            <div className="mb-6">
              <label className="block text-sm font-medium mb-2">العميل</label>
              <input
                type="text"
                name="customer"
                value={selectedInvoice.customer}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>

            {/* Row for Phone and Address */}
            <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-2">الهاتف</label>
                <input
                  type="text"
                  name="phone"
                  value={selectedInvoice.phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">العنوان</label>
                <input
                  type="text"
                  name="address"
                  value={selectedInvoice.address}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>

            {/* Governorate and Representative */}
            <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-2">المحافظة</label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={selectedInvoice.governorateId}
                  onChange={(event) => {
                    setSelectedInvoice((prevInvoice) => ({
                      ...prevInvoice,
                      governorateId: event.target.value,
                    }));
                  }}
                  name="governorateId"
                >
                  <option value="">اختر المحافظة</option>
                  {governorates.map((gov) => (
                    <option key={gov.id} value={gov.id}>
                      {gov.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">المندوب</label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={selectedInvoice.representativeId}
                  onChange={(event) => {
                    setSelectedInvoice((prevInvoice) => ({
                      ...prevInvoice,
                      representativeId: event.target.value,
                    }));
                  }}
                  name="representativeId"
                >
                  <option value="" disabled>
                    اختر المندوب
                  </option>
                  {representatives?.data.map((rep) => (
                    <option key={rep.id} value={rep.id}>
                      {rep.fullName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Table Section */}
            <div className="w-full border border-gray-200 rounded-lg p-4 max-h-52 overflow-auto shadow-sm mb-6">
              <div
                onClick={() => setIsModalOpen(true)}
                className="flex cursor-pointer w-[50px] mx-auto my-3 p-2 rounded-full bg-green-600 text-white items-center justify-center"
              >
                <FaPlus />
              </div>
              {selectedInvoiceItems.length ? (
                <div className="overflow-x-auto bg-white rounded-lg shadow-md">
                  <table className="table-auto w-full">
                    <thead className="bg-gradient-to-r from-blue-500 to-blue-700 text-white">
                      <tr>
                        <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                          Category
                        </th>
                        <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                          Quantity
                        </th>
                        <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                          Price
                        </th>
                        <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                          Total
                        </th>
                        <th className="py-3 px-4 text-sm font-semibold text-gray-900">
                          Total with Shipping
                        </th>
                        <th className="py-3 px-4 text-sm font-semibold text-center text">
                          Delete
                        </th>
                        <th className="py-3 px-4 text-sm font-semibold text-center text">
                          Edit
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {selectedInvoiceItems.map((item, idx) => (
                        <tr key={idx} className="hover:bg-gray-50">
                          <td className="py-3 px-4 text-gray-800">
                            {item.category?.name}
                          </td>
                          <td className="py-3 px-4 text-gray-800 text-center">
                            {item.quantity}
                          </td>
                          <td className="py-3 px-4 text-gray-800 text-center">
                            {item.price}
                          </td>
                          <td className="py-3 px-4 text-gray-800 text-center">
                            {item.total}
                          </td>
                          <td className="py-3 px-4 text-gray-800 text-center">
                            {item.totalWithShipping}
                          </td>
                          <td className="py-3 px-4 text-center">
                            <button
                              className="text-red-500 hover:text-red-700 transition duration-200"
                              onClick={() => handleDeleteItem(item)}
                              title="Delete Item"
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                          <td className="py-3 px-4 text-center">
                            <button
                              className="text-green-500 hover:text-green-700 transition duration-200"
                              onClick={() => handleEditItem(item)}
                              title="Edit Item"
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center py-6 text-gray-500">
                  <p className="text-lg font-medium">No items found.</p>
                </div>
              )}
            </div>

            {/* Footer Actions */}
            <div className="flex justify-end gap-4">
              <button
                className="bg-red-400 text-white py-2 px-4 rounded-md hover:bg-red-600 transition duration-200"
                onClick={handleCloseDialog}
              >
                الغاء
              </button>
              <button
                className="bg-green-700 text-white py-2 px-8 rounded-md hover:bg-green-600 transition duration-200"
                onClick={handleSaveChanges}
              >
                حفظ
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default InvoiceDialog;

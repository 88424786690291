import { FaPrint, FaTrash, FaEye } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";

const InvoicesTable = ({ currentInvoices, handlePrintInvoice, handleOpenDialog, handleDelete, showInvoice }) => {
  return (
    <table className="invoices-table">
      <thead>
        <tr>
          <th>الكود</th>
          <th>التاريخ</th>
          <th>العميل</th>
          <th>التليفون</th>
          <th>العنوان</th>
          <th>المحافظة</th>
          <th>السكرتيرة</th>
          <th>عدد الأصناف</th>
          <th>تاريخ اليومية</th>
          <th>المندوب</th>
          <th>الحالة</th>
          <th>تاريخ التعديل</th>
          <th>الفاعل</th>
          <th>الطباعه</th>
          <th>التعديل</th>
          <th>الحذف</th>
          <th>عرض</th>
        </tr>
      </thead>
      <tbody>
        {currentInvoices.map((invoice) => (
          <tr
            className="cursor-pointer hover:bg-gray-50 transition duration-200"
            key={invoice.id}
          >
            <td className="px-4 py-2 text-gray-800">{invoice.invoiceCode}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.invoiceDate.split("T")[0]}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.customer}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.phone}</td>
            <td className="px-4 py-2 text-gray-800 truncate">{invoice.address}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.governorate.name}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.createdBy || ""}</td>
            <td className="px-4 py-2 text-center text-gray-800">{invoice.invoiceCategories.length}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.dateCreated.split("T")[0]}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.representative?.fullName || "_"}</td>
            <td
              className={`px-4 py-2 font-semibold ${
                invoice.status === "Paid"
                  ? "text-green-600"
                  : invoice.status === "Pending"
                  ? "text-orange-600"
                  : "text-red-600"
              }`}
            >
              {invoice.status}
            </td>
            <td className="px-4 py-2 text-gray-800">{invoice.dateCreated.split("T")[0]}</td>
            <td className="px-4 py-2 text-gray-800">{invoice.userName}</td>
            <td className="px-4 py-2 text-center">
              <button
                className="p-2 rounded-full hover:bg-teal-100"
                onClick={() => handlePrintInvoice(invoice)}
                title="Print Invoice"
              >
                <FaPrint className="text-teal-600 text-lg" />
              </button>
            </td>
            <td className="px-4 py-2 text-center">
              <button
                className="p-2 rounded-full hover:bg-purple-100"
                onClick={() => handleOpenDialog(invoice)}
                title="Edit Invoice"
              >
                <BsPencilSquare className="text-purple-600 text-lg" />
              </button>
            </td>
            <td className="px-4 py-2 text-center">
              <button
                className="p-2 rounded-full hover:bg-red-100"
                onClick={() => handleDelete(invoice)}
                title="Delete Invoice"
              >
                <FaTrash className="text-red-600 text-lg" />
              </button>
            </td>
            <td className="px-4 py-2 text-center">
              <button
                className="p-2 rounded-full hover:bg-blue-100"
                onClick={() => showInvoice(invoice)}
                title="View Invoice"
              >
                <FaEye className="text-blue-600 text-lg" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoicesTable;

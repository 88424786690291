// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBMvSCqRuXcIGogNZAhEcVXLY35bBSf3f8",
  authDomain: "salessystem-23993.firebaseapp.com",
  projectId: "salessystem-23993",
  storageBucket: "salessystem-23993.firebasestorage.app",
  messagingSenderId: "346358631361",
  appId: "1:346358631361:web:25e6c9f65c2ebcaaa93d40",
  measurementId: "G-XSWG4TJXP1",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Register the service worker
navigator.serviceWorker.register("/firebase-messaging-sw.js")
  .then(function (registration) {
    console.log("Service Worker registered with scope:", registration.scope);
  })
  .catch(function (error) {
    console.error("Service Worker registration failed:", error);
  });

// Get the FCM token
getToken(messaging, {
  vapidKey: "BHzzUoAPXH5rbBE4GOnbe3fDQujjwFSiE-8XWfrCDa4jwDG62XyYhSs1Ik2QlRJGUMgkaZFtXjuEbVc_YLYA5Rs"
})
  .then((currentToken) => {
    if (currentToken) {
      console.log("FCM Token:", currentToken);
    } else {
      console.log("No token available.");
    }
  })
  .catch((err) => {
    console.error("Error getting FCM token:", err);
  });

export { messaging, getToken };

import { useCallback } from "react";
import logo from "../assets/images/weviira.png";
const style = `   body {
  font-family: "Arial", sans-serif;
  margin: 0 auto;
  padding: 10px;
  direction: rtl;
  color: #333;
  background-color: #f9f9f9;
}

.page-break {
  padding: 10px; 
  margin-top: 10px;
  width: 90%;
  min-height: 50vh;
  border: 1px solid #000;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 6px;
  overflow: auto;
}

.invoice-header h2 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #2c3e50;
}

.row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.client-info {
  flex: 1;
  border-radius: 4px;
  padding: 8px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.client-info p {
  margin: 3px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 12px;
  background: #fff;
}

th, td {
  border: 1px solid black !important;
  padding: 2px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.summary-table {
  margin-top: 5px;
  border: none;
  text-align: left;
}

.summary-table td {
  padding: 3px;
}

.container {
  text-align: center;
  max-width: 90vw;
  margin: 0 auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  height: 45vh;
  overflow: auto;
}

        
      `;

const usePrintInvoices = () => {
  const today = new Date();
  const optionsDate = { year: "numeric", month: "long", day: "numeric" };
  const optionsDay = { weekday: "long" };
  const arabicDate = today.toLocaleDateString("ar-EG", optionsDate);
  const arabicDay = today.toLocaleDateString("ar-EG", optionsDay);
  const printInvoices = useCallback((data) => {
    try {
      console.log(data);
      const btns = ` <div
  style="
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
  "
>
  <button
    onclick="window.print()"
    style="
      padding: 10px 20px;
      font-size: 16px;
      background-color: #4caf50;
      color: white;
      border: none;
      cursor: pointer;
    "
  >
    طباعة
  </button>
  <button
    onclick="window.location.reload()"
    style="
      padding: 10px 20px;
      font-size: 16px;
      background-color: #234356;
      color: white;
      border: none;
      cursor: pointer;
    "
  >
    عودة
  </button>
</div>
`;

      const printWindow = window.open("", "_blank");
      const invoiceData = data[0].invoices;
      const rows = data[0].invoices
        .map(
          (item) => `
    <div class="page-break">
              <div style="display: flex; justify-content: flex-end;">
  <img src='${
    logo || ""
  }' alt="Invoice Logo" direction="ltr" style="width: 100px; height: auto;">
</div>
     <div class="row">
        <div class="client-info">
                  <p><strong>الكود </strong> ${item.invoiceCode || "N/A"}</p>

          <p><strong>اسم العميل:</strong> ${item.customer || "N/A"}</p>
          <p><strong>العنوان:</strong> ${item.address || "N/A"}</p>
          <p><strong>الهاتف:</strong> ${item.phone || "N/A"}</p>
          <p><strong>المحافظه:</strong> ${
            item.representative.governorateName || "N/A"
          }</p>   

            <p><strong>الملاحظات:</strong> ${item.note || "N/A"}</p>
        </div>
        <div class="client-info">
          <p><strong>تاريخ الفاتورة:</strong> ${
            new Date(item.invoiceDate).toLocaleDateString("ar-EG") || "N/A"
          }</p>
          <p><strong>السكرتير:</strong> ${item.userName || "N/A"}</p>
          <p><strong>الهاتف:</strong> ${item.phone || "N/A"}</p>
          <p><strong>المندوب:</strong> ${
            item.representative.fullName || "N/A"
          }</p>
        </div>
      </div>

    <table>
        <thead>
          <tr>
            <th> الصنف</th>
        
        
            <th>الكمية</th>
                <th>السعر</th>
            <th>الإجمالي</th>
      
          </tr>
        </thead>
        <tbody>
          ${(item.items || [])
            .map(
              (category) => `
              <tr>
                <td>${category.category.name || "N/A"}</td>
          
                <td>${category.quantity || 0}</td>
                      <td>${category.total || 0}</td>
                <td>${category.totalWithShipping || 0}</td>
          
              </tr>
              `
            )
            .join("")}
        </tbody>
      </table>
    


    


  


    <table style="width: 40%; margin: 0 auto; border-collapse: collapse; border: 1px solid #ddd; text-align: right; background-color: #f9f9f9;">
  <thead>

  </thead>
  <tbody>
    <tr style="background-color: #f2f2f2;">
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">الاجمالي</td>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">${
        item.totalPrice - item.shipping || 0
      }</td>
    </tr>
    <tr>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">الشحن</td>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">${
        item.shipping || 0
      }</td>
    </tr>
    <tr style="background-color: #f2f2f2;">
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">الاجمالي مع الشحن</td>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">${
        item.totalPrice
      }</td>
    </tr>
  </tbody>
</table>

    </div>
    `
        )
        .join("");

      let indx = 0;
      const accountRows = `
        <div>

                  <div style="display: flex; justify-content: flex-end;">
  <img src='${
    logo || ""
  }' alt="Invoice Logo" direction="ltr" style="width: 100px; height: auto;">
</div>
          <h3 style="text-align: center;">اذن صرف بضاعه</h3>
                  <h3>المندوب   / ${
                    invoiceData[0].representative.fullName || "N/A"
                  }  </h3> 
         <h3>التاريخ / ${arabicDate}</h3>
    <h3>اليوم / ${arabicDay}</h3>
          <table class="product-table">
            <thead>
              <tr>
                <th>#</th>
                <th width="300px">بيان الاصناف</th>
                <th>العدد</th>
              </tr>
            </thead>
            <tbody>
              ${data[0].invoices
                .map(
                  (item, index) => `
                      ${
                        item.items && item.items.length > 0
                          ? item.items
                              .map(
                                (category, idx) => `
                                  <tr>
                                    <td style="padding: 8px; text-align: center;">${++indx}</td>
                                    <td style="padding: 8px; text-align: center;">${
                                      category.category.name || "N/A"
                                    }</td>
                                    <td style="padding: 8px; text-align: center;">${
                                      category.quantity || 0
                                    }</td>
                                  </tr>
                                `
                              )
                              .join("")
                          : `<tr><td colspan="3" style="padding: 8px; text-align: center;">لا توجد بيانات</td></tr>`
                      }
                    `
                )
                .join("")}
            </tbody>
          </table>
        </div>
      `;

      const invoiceDetailsRows = invoiceData
        .map(
          (invoice) => `
      <tr>
        <td>${invoice.invoiceCode || "N/A"}</td>
        <td>${
          invoice.items.map((item) => item.category.name).join(", ") || "N/A"
        }</td>
              <td>${invoice.items.length || 0}</td>
        <td>${invoice.shipping || 0}</td>
        <td>${invoice.totalPrice - invoice.shipping || 0}</td>
                <td>${invoice.totalPrice || 0}</td>

        <td>${invoice.representative.governorateName || "N/A"}</td>
        <td>${invoice.status || "N/A"}</td>
      </tr>
    `
        )
        .join("");

      let finalTotal = 0;
      let totalQuantity = 0;
      let totalPrice = 0;
      let totalCount = 0;

      for (let i = 0; i < invoiceData.length; i++) {
        totalQuantity += invoiceData[i].totalPrice - invoiceData[i].shipping;
        totalPrice += invoiceData[i].shipping;
        totalCount += invoiceData[i].items.length;
      }

      finalTotal = totalQuantity + totalPrice;

      const totalsTable = `
  <table border="1" style="width: 50%; text-align: center; border-collapse: collapse;">
    <thead>
      <tr>
              <th> اجمالي  العدد</th>
        <th> اجمالي القيمه</th>
        <th> اجمالي الشحن</th>
        <th> الاجمالي النهائي</th>
      </tr>
    </thead>
    <tbody>
      <tr>
            <td>${totalCount}</td>
        <td>${totalQuantity}</td>
        <td>${totalPrice}</td>
        <td>${finalTotal}</td>
      </tr>
    </tbody>
  </table>
`;

      const invoiceDetailsTable = `
<div class="page-break"> 
              <div style="display: flex; justify-content: flex-end;">
  <img src='${
    logo || ""
  }' alt="Invoice Logo" direction="ltr" style="width: 100px; height: auto;">
</div>
          
            <h3>المندوب   / ${
              invoiceData[0].representative.fullName || "N/A"
            }  </h3> 
       <h3>التاريخ / ${arabicDate}</h3>
    <h3>اليوم / ${arabicDay}</h3>
    

  <table border="1" style="width: 100%; text-align: center; border-collapse: collapse;">
    <thead>
      <tr>
        <th>كود الفاتورة</th>
        <th> بيان  الاصناف</th>

        <th>عدد الاصناف</th>
        <th>الشحن</th>
        <th>القيمة</th>
        <th>الاجمالي</th>
        <th>المحافظة</th>
        <th>الحالة</th>
      </tr>
    </thead>
    <tbody>
      ${invoiceDetailsRows || `<tr><td colspan="6">لا توجد بيانات</td></tr>`}
    </tbody>
  </table>

  ${totalsTable}
<h3 style="margin-top: 20px; text-align: center">  ${totalCount} / العدد  </h3>


        <h3> المستحق /    </h3> 
            <h3>النقدي   /   </h3> 
            <h3>المسدد   /   </h3> 


            
</div>
`;
      let ItemsCounts = 0;
      data[0].categoryTotals.forEach((category) => {
        ItemsCounts += category.totalQuantity;
      });

      const categoryTotalsRows = data[0].categoryTotals
        .map(
          (total) => `
            <tr>
              <td>${total.categoryName || "N/A"}</td>
              <td>${total.totalQuantity || 0}</td>
            </tr>
          `
        )
        .join("");

      const categoryTotals = `
    <div width="70%" style="margin: 0 auto">  

        <table border="1" style="width: 200px; margin-top: 70px; text-align: center; border-collapse: collapse;">
          <thead>
            <tr>
              <th width="120px"> مجموعه الاصناف</th>
              <th> العدد</th>
            </tr>
          </thead>
          <tbody>
            ${
              categoryTotalsRows ||
              `<tr><td colspan="2">لا توجد بيانات</td></tr>`
            }
          </tbody>
        </table> 
    <p> اجمالي العدد: ${ItemsCounts} </p>
                <p>توقيع المندوب: ____________________</p>
    
    </div>
      `;

      const invoiceTables = `
        <h3>المندوب   / ${
          invoiceData[0].representative.fullName || "N/A"
        }  </h3> 
            <h3> التاريخ /    </h3> 
            <h3>اليوم   /   </h3> 
        <table>
          <thead>
            <tr>
              <th>  بيان الاصناف</th>
              <th>العدد</th>
            </tr>
          </thead>
          <tbody>
            ${data
              .flatMap((item) => item.items || [])
              .map(
                (category) => `
                  <tr>
                    <td>${category.category.group.name || "N/A"}</td>
                    <td>${category.quantity || 0}</td>
                  </tr>
                `
              )
              .join("")}
          </tbody>
        </table>
        <p>التوقيع: ____________________</p>
        <hr>
      `;

      const invoicesPage = `  <html>
          <head>
            <title>فواتير</title>
  
          </head> 
          <style>${style}
          </style>
          <body>
  <!-- First Section: Rows -->
  <div style="min-height: 100vh; display: flex; justify-content: center; align-items: center; box-sizing: border-box; flex-direction: column;">
    ${rows}
  </div>



  ${btns}

</body>


        </html>
      `;

      const permissionPage = `  <html>
      <head>
        <title>اذن الصرف</title>

      </head> 
          <style>${style}
          </style>
      <body>

<div style=" display: flex; justify-content: center; align-items: center; box-sizing: border-box; flex-direction: column;">
<div style="margin-top: 10px;">
  ${accountRows}
  ${categoryTotals}
</div>
</div>

${btns}

</body>


    </html>
  `;
      const accountsPage = `  <html>
  <head>
    <title>تجميع الحساب</title>

  </head> 
  <style>
  
  ${style}
  </style>
  <body>

 

<div style=" display: flex; justify-content: center; align-items: center; box-sizing: border-box;">
${invoiceDetailsTable} 

</div>
${btns}
</body>


</html>
`;

      const permissionsTab = window.open("", "permissionsTab");
      permissionsTab.document.write(permissionPage);
      permissionsTab.document.close();

      const invoicesTab = window.open("", "invoicesTab");
      invoicesTab.document.write(invoicesPage);
      invoicesTab.document.close();

      const accountsTab = window.open("", "accountsTab");
      accountsTab.document.write(accountsPage);
      accountsTab.document.close();
    } catch (error) {
      console.error("Error printing invoices:", error);
    }
  }, []);

  return printInvoices;
};

export default usePrintInvoices;
